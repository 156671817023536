import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import handoff from '../views/handoff.vue'

const routes = [
    // 首页
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/handoff',
        name: 'handoff',
        component: handoff
    },
    // 客服端
    {
        path: '/service',
        name: 'service',
        component: () => import('../views/serviceView.vue'),

        children: [
            // 单个导航====mainelement
            // 控制面板
          //   {
          //       path: '1',
          //       component: () => import('../components/MainElement/ControlView.vue'),
          // },
          {
              path: '',
            component: () => import('../components/MainElement/ControlView1.vue'),
        },
            // 账号设置
            {
                path: 'setting',
                component: () => import('../components/MainElement/SettingView.vue'),
            },
            // 欠款平台
            {
                path: 'debt',
                component: () => import('../components/MainElement/DebtView.vue'),
            },
            // 充值记录
            {
                path: 'charge',
                component: () => import('../components/MainElement/ChargeView.vue'),
            },
            // 多个导航 == OrderElement == 线索管理
            // 销售工作台
            {
                path: 'professional',
                name: 'professional',
                component: () => import('../components/CustomerElement/ProfessionalView.vue'),
            },
            {
                path: 'salesTable',
                component: () => import('../components/CustomerElement/SalesTableView.vue'),
            },
            {
                path: 'stayCollection',
                component: () => import('../components/CustomerElement/stayCollectionView.vue'),
            },
            {
                path: 'flowupinfo',
                component: () => import('../components/CustomerElement/FlowupInfomation/tabletitle.vue'),
            },
            // 销售客户管理
            {
                path: 'clues',
                name: 'clues',
                component: () => import('../components/CustomerElement/CluesView.vue'),
            },
            // 销售客户公海
            {
                path: 'sea',
                component: () => import('../components/CustomerElement/SeaView.vue'),
            },
              // 销售客户公海
              {
                path: 'new',
                component: () => import('../components/CustomerElement/SeaNewView.vue'),
            },
            // 调解员客户公海
            {
                path: 'lawsea',
                component: () => import('../components/CustomerElement/LawyerSeaView.vue'),
            },
            // 调解员公海查看
            {
                path: 'lawseadetail',
                name: 'lawseadetail',
                component: () => import('../components/CustomerElement/LawyerSeaDetailView.vue'),
            },
            // 调解员客户管理
            {
                path: 'lawtotal',
                component: () => import('../components/CustomerElement/LawyerTotalView.vue'),
            },
            // 待办看台
            {
                path: 'toDo',
                name: 'toDo',
                component: () => import('../components/Service/ToDoView.vue'),
            },
            // 待办记录详情
            {
                path: 'toDoDetail',
                component: () => import('../components/Service/components/toDoDetailView.vue'),
            },
             // 服务助理客户管理
             {
                path: 'assistant',
                component: () => import('../components/CustomerElement/LawassistantTotalView.vue'),
            },
            
            // 备注跟进
            {
                path: 'remarksFollow',
                name: 'remarksFollow',
                component: () => import('../components/CustomerElement/remarksFollow.vue'),
            },
            // 调解员客户查看
            {
                path: 'lawtotaldetail',
                name: 'lawtotaldetail',
                component: () => import('../components/CustomerElement/LawTotalDetailView.vue'),
            },

            // 案件管理
            {
                path: 'caselist',
                component: () => import('../components/CaseElement/CaseView.vue'),
            },
            // 案件详情
            {
                path: 'casedetail',
                name: 'casedetail',
                component: () => import('../components/CaseElement/RoundElement/CaseDatailView.vue'),
            },
            // 加急审核
            {
                path: 'urgent',
                component: () => import('../components/CaseElement/UrgentView.vue'),
            },
            // 加急审核详情
            {
                path: 'detail1',
                name: 'detail1',
                component: () => import('../components/CaseElement/components/DetailView.vue'),
            },
            // 搁置审核
            {
                path: 'shelve',
                component: () => import('../components/CaseElement/ShelveView.vue'),
            },
            // 调解审核
            {
                path: 'allcheck',
                component: () => import('../components/CaseElement/AllCheckView.vue'),
            },
            // 调解审核
            {
                path: 'processQuality',
                component: () => import('../components/CaseElement/ProcessQualityView.vue'),
            },
            // 调解审核详情
            {
                path: 'alldetail',
                name: 'alldetail',
                component: () => import('../components/CaseElement/AllElement/AllDetailView.vue'),
          },
          {
            path: 'Rights',
            name: 'Rights',
            component: () => import('../components/CaseElement/Rights.vue'),
          },
          //维权详情
          {
            path: 'Rightsdetail',
            name: 'Rightsdetail',
            component: () => import('../components/CaseElement/RightsElement/Rightsdetail.vue'),
          },
          //协议审核
          {
            path: 'agreement',
            name: 'agreement',
            component: () => import('../components/CaseElement/agreement.vue'),
          },
            //协议审核
            {
                path: 'Relief',
                name: 'Relief',
                component: () => import('../components/CaseElement/Relief.vue'),
              },
          {
            path: 'Pre_litigation',
            name: 'Pre_litigation',
            component: () => import('../components/Audit/Pre_litigation.vue'),
          },  
          {
            path: 'lawsuit',
            name: 'lawsuit',
            component: () => import('../components/Audit/lawsuit.vue'),
          },
          {
            path: 'execute',
            name: 'execute',
            component: () => import('../components/Audit/execute.vue'),
          },
          {
            path: 'auditdetail',
            name: 'auditdetail',
            component: () => import('../components/Audit/auditdetail.vue'),
      },
            {
                path: 'shelvedetail',
                name: 'shelvedetail',
                component: () => import('../components/CaseElement/ShelveElement/ShelveDetailView.vue'),
            },
            // 系统设置 == systemElement == 系统设置
            // 调解暂停设置
            {
                path: 'pause',
                component: () => import('../components/SystemElement/PauseView.vue'),
            },
            // 用户标签
            {
                path: 'usertag',
                component: () => import('../components/SystemElement/UserTagView.vue'),
            },
             // 工作台标签
             {
                path: 'workbenchtag',
                component: () => import('../components/SystemElement/WorkbenchTagView.vue'),
            },
            // 一级跟进动作
            {
                path: 'follow',
                component: () => import('../components/SystemElement/FollowView.vue'),
            },
            // 多级跟进动作
            {
                path: 'followmore',
                component: () => import('../components/SystemElement/FollowMoreView.vue'),
            },
            // 线索来源
            {
                path: 'origin',
                component: () => import('../components/SystemElement/OriginView.vue'),
            },
            // 流失原因
            {
                path: 'reason',
                component: () => import('../components/SystemElement/ReasonView.vue'),
            },
            // 用户资料
            {
                path: 'file',
                component: () => import('../components/SystemElement/FileView.vue'),
            },
            // 案件设置
            {
                path: 'case',
                component: () => import('../components/SystemElement/CaseSetView.vue'),
            },
            // 调解方法
            {
                path: 'over',
                component: () => import('../components/SystemElement/OverView.vue'),
            },
            // 销售激励设置
            {
                path: 'incentive',
                component: () => import('../components/SystemElement/IncentiveView.vue'),
          },
          // 操作日志
          {
            path: 'tuomin',
            component: () => import('../components/SystemElement/tuomin.vue'),
          },
        // 操作日志
        {
          path: 'tuominlist',
          component: () => import('../components/SystemElement/tuominlist.vue'),
      },
             // 操作日志
             {
              path: 'incentives',
              component: () => import('../components/SystemElement/Incentives.vue'),
          },
            // 转让记录
            {
                path: 'transfer',
                component: () => import('../components/SystemElement/transferView.vue'),
            },
            // 财务管理 === 订单管理
            {
                path: 'order',
                component: () => import('../components/OrderElement/OrderView.vue'),
            },
            // 财务管理 === 退款管理
            {
                path: 'refund',
                component: () => import('../components/OrderElement/RefundView.vue'),
            },
            // 订单详情
            {
                path: 'detail',
                name: 'detail',
                component: () => import('../components/OrderElement/DetailView.vue'),
            },
            // 退款订单详情
            {
                path: 'refundDetail',
                name: 'refundDetail',
                component: () => import('../components/OrderElement/RefundDetailView.vue'),
            },
            // 补录订单
            {
                path: 'check',
                component: () => import('../components/OrderElement/CheckView.vue'),
            },
            // 订单详情
            {
                path: 'checkdetail',
                name: "checkdetail",
                component: () => import('../components/OrderElement/components/CheckDetailView.vue'),
            },
            // 短信记录详情
            {
                path: 'msgDetail',
                name: "msgDetail",
                component: () => import('../components/MessageElement/components/msgDetailView.vue'),
            },
            // 抽查记录详情
            {
                path: 'spotCheckDetail',
                name: "spotCheckDetail",
                component: () => import('../components/spotCheck/spotCheckDetailView.vue'),
            },
            // 跟进详情
            {
                path: 'management',
                name: 'management',
                component: () => import('../components/ManagementElement/ManagementView.vue'),
            },
            // 查看详情===跟进
            {
                path: 'management1',
                name: 'management1',
                component: () => import('../components/ManagementElement/Management1View.vue'),
            },
            // 权限管理 === 组织架构管理
            {
                path: 'team',
                component: () => import('../components/AuthorityElement/TeamView.vue'),

            },
            // 权限管理 === 账号管理
            {
                path: 'account',
                component: () => import('../components/AuthorityElement/AccountView.vue'),
            },
            // 控制中台 === 控制中台
            {
                path: 'flowAll',
                component: () => import('../components/MainElement/FlowAllView.vue'),
            },
            // 短信管理 === 发送记录
            {
                path: 'sendMessage',
                component: () => import('../components/MessageElement/SendMessageView.vue'),
            },
            // 短信管理 === 新建发送
            {
                path: 'creatMessage',
                component: () => import('../components/MessageElement/CreatMessageView.vue'),
            },
            // 短信管理 === 发送记录
            // {
            //     path: 'messageRecords',
            //     component: () => import('../components/MessageElement/MessageRecordsView.vue'),
            // },
            // 标签管理 === 新建标签
            {
                path: 'createTag',
                component: () => import('../components/TagElement/CreateTagView.vue'),
            },
            // 调解员运维-用户查询
            {
                path: "userQuery",
                name: "userQuery",
                component: () => import("../components/LawWorksOPS/UserQuery.vue")
            },
            // 播报设置-播报目标填报
            {
                path: "target",
                component: () => import("../components/BroadcastElement/Target.vue")
            },
            // 播报设置-播报口号设置
            {
                path: "slogan",
                component: () => import("../components/BroadcastElement/Slogan.vue")
            },
            // 播报设置-团队人力填报
            {
                path: "manpower",
                component: () => import("../components/BroadcastElement/Manpower.vue")
            },
            // 播报设置-群机器人列表
            {
                path: "robot",
                component: () => import("../components/BroadcastElement/robot.vue")
            },
            // 小程序--投诉/退单
            {
                path: "applet",
                component: () => import("../components/AppletElement/Applet.vue")
            },
            // 小程序--投诉/退单
            {
                path: "appletDetail",
                name: "appletDetail",
                component: () => import("../components/AppletElement/AppletDetail.vue")
            },
            {
                path: 'auditmanage',
                component: () => import('../components/AuditElement/AuditManage.vue'),
            },
            {
                path: 'transferof',
                component: () => import('../components/TransferofElement/AuditManage.vue'),
            },
            {
                path: 'auditManageDetail',
                component: () => import('../components/AuditElement/AuditManageDetailView.vue'),
            },
            {
                path: 'pkList',
                component: () => import('../components/HdElement/PKlist.vue'),
            },
            {
                path: 'pkvue',
                component: () => import('../components/HdElement/PKvue.vue'),
            },
            {
                path: 'grvue',
                component: () => import('../components/HdElement/GRpk.vue'),
            },
            {
                path: 'tdvue',
                component: () => import('../components/HdElement/TDpk.vue'),
            },
            {
                path: 'grbj',
                component: () => import('../components/HdElement/GRbj.vue'),
            },
            {
                path: 'tdbj',
                component: () => import('../components/HdElement/TDbj.vue'),
            },
            {
                path: 'hdvue',
                component: () => import('../components/HdElement/HDvue.vue'),
            },
            {
                path: 'appchat',
                component: () => import('../components/Audit/appchat.vue'),
            },
            {
                path: 'Credit',
                component: () => import('../components/Audit/Credit.vue'),
            },
            {
                path: 'loan',
                component: () => import('../components/Audit/loan.vue'),
            },
            // 抽查记录
            {
                path: 'spotCheck',
                component: () => import('../components/spotCheck/spotCheckView.vue'),
            },
          //线索回收
          {
            path: 'xslist',
            component: () => import('../components/SystemElement/xslist.vue'),
          },
           //设置回收
           {
            path: 'szhs',
            component: () => import('../components/SystemElement/szhs.vue'),
          },
            // 销售抢量活动
            {
                path: "seizeQuantityLog",
                name: "seizeQuantityLog",
                component: () => import("../components/SeizeQuantity/Log.vue")
            },
            {
                path: "Salestarget",
                name: "Salestarget",
                component: () => import("../components/Salestarget/list.vue")
            },
            {
                path: "Salestargetdetail",
                name: "Salestargetdetail",
                component: () => import("../components/Salestarget/dialog.vue")
            }
        ]
    }
]
const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
